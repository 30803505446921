<template>
  <!--  <div class="user_card_tab">-->
  <!--    <div class="item" :class="{'xuan': type == 'Refunding'}" @click="changeType('Refunding')">-->
  <!--      <span>{{ $t('Refunding') }}</span>-->
  <!--      <em></em>-->
  <!--    </div>-->
  <!--    <div class="item" :class="{'xuan': type == 'Refunded'}" @click="changeType('Refunded')">-->
  <!--      <span>{{ $t('Refunded') }}</span>-->
  <!--      <em></em>-->
  <!--    </div>-->
  <!--  </div>-->
  
    <div class="order_list" v-if="returnOrderList.length>0">
      <div class="order_header">
        <div class="item Commodity">{{ $t('Commodity Information') }}</div>
        <div class="item Consignee">{{ $t('Consignee') }}</div>
        <div class="item Money">{{ $t('Money') }}</div>
        <div class="item States">{{ $t('Order Status') }}</div>
        <div class="item Operation">{{ $t('Operation') }}</div>
      </div>
  
      <table class="order_table" cellspacing=0 v-for="(order,index) in returnOrderList" v-bind:key="index">
        <tr class="title">
          <td>
            <span>{{order.createdDate}}</span>
            <span>{{ $t('Application Number') }}:<b>{{order.no}}</b></span>
           <!-- <div class="delete_order" @click="deleteOrder(order.id, index)"><img title="delete" src="~img/cart_shanchu_02.svg"></div>-->
          </td>
        </tr>
        <tr>
          <td class="no1">
            <div class="pro_main">
              <div class="pro_img">
                <JLImg v-if="order.productCoverImg" :src="order.productCoverImg" />
              </div>
              <div class="pro_info">
                <div class="pro_name text_hide2 tl">
                  <!--<em class="pro_biaoqian new_pro">
                    <img src="~img/new.svg">NEW
                  </em>-->
                  {{order.productName}}
                </div>
                <div class="pro_sx">
                  <span>{{ order.specName}}</span>
                </div>
              </div>
              <div class="pro_num">
                <span>× {{order.purchaseNum}}</span>
                <span>{{JLTools.forPrice(order.specAmount)}}</span>
              </div>
            </div>
          </td>
          <td class="no2">
            <div class="user_name pl10 pr10">{{order.nickname}}</div>
          </td>
          <td class="no3">
            <b>{{$t('Total')}}:{{JLTools.forPrice(order.applyAmount)}}</b>
            <span v-if="false">{{$t('Freight')}}:+Rs.0</span>
            <span v-if="false">{{$t('Discount')}}:-Rs.100</span>
          </td>
          <td class="no4">
            <span v-if="!JLTools.isNull(order.refund)">{{order.refundStatus}}</span>
            <span v-else>{{order.refundApplyStatus==='NODISPOSED' ? 'nodisposed' : order.refund.refundStatus && order.refundApplyStatus==='DISPOSING' ? 'disposing' : order.refund.refundStatus}}</span>
            <a @click="toPage('REFUNDMAIN',order.id,'sq')">{{$t('Order Details')}}</a>
          </td>
        </tr>
      </table>
    </div>
    <div class="no_order" v-else>
      <img src="~img/noorder.png">
    </div>
    <div class="w12fenye ml0">
      <div class="fenye_left">{{ $t('About') }} <b>{{ returnOrderListCount }}</b> {{ $t('results were found') }}</div>
      <div class="fenye_right">
        <Pagination :config="{total:returnOrderListCount,pageSize:4}" v-if="returnOrderListCount" @currentPage="queryReturnOrderList"></Pagination>
      </div>
    </div>
  </template>
  
  <script>
    import Pagination from "@/components/Pagination"
    import useStore from '@/store/pinia'
    const { userStore } = useStore()
    
    export default {
      name: "returnOrderList",
      components: {Pagination},
      data() {
        return {
          type: '',//退单类型
          returnOrderList: [],//退单列表
          returnOrderListCount: 0,//退单数量
          page: 0
        }
      },
      created() {
      },
      mounted() {
      },
      watch: {
        type(val, newval) {
          if(val != newval) {
            this.page = 0
            this.size = 4
          }
          this.queryReturnOrderList()
          this.queryReturnOrderNum()
        }
      },
      methods: {
        //查询退单列表
        queryReturnOrderList(value) {
          this.page = this.JLTools.isNull(value) ? this.page : value - 1
          let data = {
            'userId.equals': userStore.userInfo.userId,
            'sort': 'createdDate,desc',
            'page': this.page,
            'size': 4
          }
          let url = 'services/payment/api/refund-applies'
          this.$api.get(url, data).then(async res => {
            for(let item of res) {
              let quertData = {
                'orderId.equals': item.orderId,
                'userId.equals': userStore.userInfo.userId
              }
              item.refund = await this.queryRefuend(quertData) || {}
              item.createdDate = this.JLTools.formatDate(item.lastModifiedDate)
            }
            this.returnOrderList = res
            // console.log(this.returnOrderList)
          })
        },
        async queryRefuend(data) {
          return this.$api.get('services/payment/api/refunds', data).then(res => {
            return res[0]
          })
        },
        //查询退单列表数量
        queryReturnOrderNum() {
          this.returnOrderListCount = ''
          let data = {
            'userId.equals': userStore.userInfo.userId
          }
          let url = 'services/payment/api/refund-applies/count'
          this.$api.get(url, data).then(res => {
            this.returnOrderListCount = res
          })
        },
        /**
         * 跳转页面
         * @param pageName
         * @param orderId
         * @param orderNo
         * @param amount
         */
        toPage(pageName,orderId,type) {
          this.$router.push({
            name: pageName,
            params: {refundId:orderId,type:type}
          })
        },
        changeType(type) {
          this.type = type
          userStore.setUserShowPage({ selectType: type })
        }
      }
    }
  </script>
  
  <style scoped>
    .user_body{ float: right; width: 960px; min-height: calc(100vh - 150px); margin: 20px 0 20px 20px;}
    .user_card_tab{ float: left; width: 100%; height: 61px; background: #fff; border-bottom: 1px solid #eee;}
    .user_card_tab .item{ float: left; line-height: 60px; position: relative; margin: 0 0 0 20px; font-size: 13px; cursor: pointer}
    .user_card_tab .item em{ position: absolute; display: none; width: 50px; height: 3px; background: var(--btnBgColor); bottom: 10px; left: 50%; margin-left: -25px;}
    .user_card_tab .item:hover{}
    .user_card_tab .item.xuan{ color: var(--btnBgColor);}
    .user_card_tab .item.xuan em{display: block;}
    .user_card_tab .search{ float: right; margin: 17.5px 15px;}
    .user_card_tab .search input{ float: left; width: 140px; height: 25px; border: 1px solid #ccc;}
    .user_card_tab .search .btn{ float: left; background: #f5f5f5; border: 1px solid #ccc; border-left: 0px; height: 25px; line-height: 23px; padding: 0 8px;}
    .user_card_tab .search a{ float: left; line-height: 25px; margin-left: 10px;}
    .user_card_tab .search a img{ width: 15px; height: 15px; float: right; margin: 5px 0 0 2px;}
    .more_filtering{ float: left; width: 100%; background: #fff;}
  
    .order_list{ float: left; width: 100%; padding: 15px; background: #fff;}
    .order_header{ float: left; width: 100%; height: 35px; background: #eee;}
    .order_header .item{ float: left; text-align: center; line-height: 35px; font-weight: bold;}
    .order_header .item.Commodity{ width: 450px; text-align: left; padding-left: 15px;}
    .order_header .item.Consignee{ width: 120px;}
    .order_header .item.Money{ width: 130px;}
    .order_header .item.States{ width: 120px;}
    .order_header .item.States img{ width: 15px; height: 15px;}
    .order_header .item.Operation{ width: 110px;}
    .order_table{ float: left; width: 100%; margin-top: 15px; border: 1px solid #e1e1e1;}
  
    .order_table:hover .delete_order{ display: block !important;}
    .order_table tr{ float: left; width: 100%; border-bottom: 1px solid #e1e1e1;}
    .order_table tr:last-child{ border-bottom: 0px;}
    .order_table tr.title{ line-height: 35px; background: #f5f5f5;}
    .order_table tr.title td{ width: 930px;}
    .order_table tr.title td span{ float: left; margin-left: 15px; color: #999; font-size: 13px;}
    .order_table tr.title td span b{ color: #000; margin-left: 2px;}
    .order_table tr.title td .delete_order{ float: right; width: 16px; height: 16px; margin: 9px 15px 5px 0; display: none;}
    .order_table tr.title td .delete_order img{ float: left;}
    .order_table tr.title td .delete_order:hover{ cursor: pointer;}
    .order_table td{ border-left: 1px solid #eee; text-align: center;}
    .order_table td:first-child{ border-left: 0px;}
    .order_table td.no1{ width: 470px;}
    .order_table td.no1 .pro_main{ float: left; width: 100%; padding: 15px; border-bottom: 1px solid #e1e1e1;}
    .order_table td.no1 .pro_main:last-child{ border-bottom: 0px;}
    .order_table td.no1 .pro_img{ float: left; width: 70px; height: 70px;}
    .order_table td.no1 .pro_info{ float: left; width: 258px; height: 70px; padding: 0 10px;}
    .order_table td.no1 .pro_info .pro_name{ float: left; width: 100%; line-height: 20px;}
    .order_table td.no1 .pro_info .pro_sx{ float: left; width: 100%; height: 20px; margin-top: 4px; text-align: left; color: #999;}
    .order_table td.no1 .pro_info .pro_sx span{ margin-right: 10px;}
    .order_table td.no1 .pro_num{ float: left; width: 89px; height: 70px;}
    .order_table td.no1 .pro_num span{ float: left; width: 100%; text-align: center; line-height: 20px; color: #999;}
    .order_table td.no2{ width: 120px;}
    .order_table td.no2 .user_name{     width: 120px; word-wrap: break-word;}
    .order_table td.no3{ width: 130px;}
    .order_table td.no3 b{ float: left; width: 100%; line-height: 20px;}
    .order_table td.no3 span{ float: left; width: 100%; line-height: 20px; color: #999;}
    .order_table td.no4{ width: 120px;}
    .order_table td.no4 span{ float: left; width: 100%; text-align: center; color: #999; line-height: 20px;}
    .order_table td.no4 a{ float: left; width: 100%; text-align: center; color: var(--btnBgColor); line-height: 20px;}
    .order_table td.no4 a:hover{ cursor: pointer; text-decoration: underline;}
    .order_table td.no5{ width: 110px;}
    .order_table td.no5 button{ float: left; width: 90px; height: 25px; margin: 0 10px; background: #fff; border-radius: 5px; border: 1px solid var(--btnBgColor); color: var(--btnBgColor); font-size: 10px;}
    .order_table td.no5 button:hover{ cursor: pointer; background: var(--btnBgColor); color: #fff;transition:all 0.3s;}
  
    .fenye{ float: left; width: 100%; height: 60px; background: #fff; margin: 10px 0 20px 10px;}
    .fenye_left{ float: left; line-height: 60px; margin-left: 10px; font-size: 13px; color: #666;}
    .fenye_left b{ font-size: 14px; color: #000;}
    .fenye_right{ float: right; padding: 12px 0; margin-right: 5px;}
    .fenye_right button{ float: left; height: 36px; min-width: 36px; line-height: 34px; padding: 0 10px; border: 1px solid #ccc; background: #fff; margin-right: 5px; font-size: 14px;}
    .fenye_right button:hover{ cursor: pointer; background: #f5f5f5;}
    .fenye_right button.xuan{ background: var(--btnBgColor); border: 1px solid var(--btnBgColor); color: #fff;}
    .fenye_right_previous img{ float: left; width: 16px; height: 16px; margin: 8px 2px 8px 0; transform: rotate(90deg);}
    .fenye_right_previous span{ float: left; margin-right: 3px;}
    .fenye_right_next img{ float: left; width: 16px; height: 16px; margin: 8px 0 8px 2px; transform: rotate(270deg);}
    .fenye_right_next span{ float: left; margin-left: 3px;}
    .fenye_right input{ float: left; width: 50px; height: 36px; border: 1px solid #ccc; margin-right: 5px;}
    .fenye_right .txt{ float: left; line-height: 36px; font-size: 14px; margin: 0 10px 0 5px;}
    .fenye_right_none{ color: #ccc;}
    .fenye_right_none:hover{ background: #fff !important; cursor: no-drop !important;}
  
    .pro_biaoqian{ color: #fff; line-height: 20px; border-radius: 3px; font-size: 10px; padding: 0 5px;}
    .pro_biaoqian img{ width: 12px; height: 12px; float: left; margin: 4px 2px 4px 0;}
    .new_pro{ background: #0C5CA8;}
    .hot_pro{ background: #fe6e2f;}
    .no_order{ float: left; width: 100%; height: 580px; background: #fff; display: flex; align-items: center; justify-content: center;}
  </style>
  