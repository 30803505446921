<script setup>
  import { transformString, paymentIconMap } from '@/views/order/compontent/handlerOrder'
  import { OrderStatus2 } from '@/enum/haier'
  import operationView from './operation'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits, nextTick } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const router = useRouter()
  const { userStore } = useStore()
  const { proxy } = getCurrentInstance()

  const props = defineProps({
    list: {
      type: Array
    },
    noListTitle: {
      type: String
    }
  })

  const orderOsRef = ref()

  const data = reactive({
    curIndex: 0,
    orderInfo: {} // 操作上传凭证数据
  })

  // 返回首页
  const goHome = () => {
    proxy.$router.push({
      name: 'home'
    })
  }

  // 传入状态更改状态专属图标和文本内容
  const getOrderStatusByType = (type) => {
    if (!type) return
    const status = OrderStatus2.find((status) => status.type === type)
    return status || null
  }

  onMounted(() => {
    // search()
  })
</script>

<template>
  <div>
    <div class="order-list-box" v-if="list.length > 0">
      <div v-for="(order, index) in list" :key="index" class="order-item">
        <div class="info-header rowBS" data-remark="订单信息">
          <div class="info-item">
            <div>Order Number</div>
            <div class="user-select-text">{{ order.mainOrderNo }}</div>
          </div>
          <div class="info-item" v-if="order.orderStatus">
            <div>Status</div>
            <div class="btn-status" :class="`status-${order.orderStatus}`">{{ getOrderStatusByType(order.orderStatus).lable }}</div>
          </div>
          <div class="info-item">
            <div>Payment Method</div>
            <div class="rowSC channel-box">
              <img
                v-if="paymentIconMap[order.channel]"
                class="payment-selection-icon"
                :src="paymentIconMap[order.channel].src"
                :style="{ width: paymentIconMap[order.channel].width }"
                :alt="order.channel"
              />
              <div>{{ transformString(order.channel) }}</div>
            </div>
          </div>
          <div class="info-item">
            <div>Placed at</div>
            <div>{{ JLTools.formatDateTime(order.mainOrderCreatedDate) }}</div>
          </div>
          <div class="info-item">
            <div>Receiver</div>
            <div>{{ order.subOrders[0].consignee }}</div>
          </div>
          <div class="info-item">
            <div>Total amount</div>
            <div>{{ JLTools.forPrice(order.totalPaidAmount) }}</div>
          </div>
        </div>
        <div class="table-box">
          <div v-for="(spec, specIndex) in order.subOrderSpecs" :key="specIndex" class="table-item-box rowSS">
            <JLImg class="pro-img" v-if="spec.specCoverImg" :src="spec.specCoverImg" />
            <div class="pro-info columnBS">
              <div>
                <div class="pro-name text_hide2">{{ spec.productName }}</div>
                <div class="spec-name text_hide2">{{ spec.specName }}</div>
                <div>Quantity: {{ spec.purchaseNum }}</div>
              </div>
              <div>Total：{{ JLTools.forPrice(spec.amount * spec.purchaseNum) }}</div>
            </div>
          </div>
        </div>
        <div class="opt-box">
          <operation-view :orderInfo="order" :curIndex="index"></operation-view>
        </div>
      </div>
    </div>
    <div class="no-order-box" v-else>
      <div class="columnCC">
        <p>{{ props.noListTitle }}</p>
        <div class="btn mt30 btn_color" @click="goHome">Continue Shopping</div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .btn-styles(@borderColor: #faecd8, @bgColor: #fcf6ec, @textColor: #fcf6ec) {
    border: 1px solid @borderColor;
    background: @bgColor;
    color: @textColor;
  }

  .order-list-box {
    width: 100%;
    background: #fff;
    .order-item {
      margin-bottom: 26px;
      border: 1px solid #dcdfe6;
      border-radius: 4px;
    }
  }

  .order-item {
    .info-header {
      padding: 24px;
      border-bottom: 1px solid #dcdfe6;
      .info-item {
        text-align: left;
        .btn-status {
          border-radius: 4px;
          padding: 6px 12px;
          font-size: 12px;
          line-height: 12px;
          .btn-styles(#FCF6EC, #FAECD8, #E6A23C);
        }
        .status-UNDISTRIBUTED {
          .btn-styles(#CAE3F4, #EEF6FB, #53a4db);
        }
        .status-DISTRIBUTED,
        .status-RECEIPTED {
          .btn-styles(#E1F3D8, #F0F9EB, #67c23a);
        }
        .status-CLOSED,
        .status-PAYMENT_FAILED {
          .btn-styles(#FDE2E2, #FEF0F0, #F56C6C);
        }
        .channel-box {
          margin-top: 1px;
          .payment-selection-icon {
            margin-right: 8px;
          }
        }
        div {
          line-height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #909399;
          &:first-child {
            font-weight: 600;
            color: #303133;
          }
        }
      }
    }
    .table-box {
      .table-item-box {
        padding: 18px;
        font-size: 14px;
        line-height: 20px;
        color: #303133;
        font-weight: 600;
        border-bottom: 1px solid #dcdfe6;
        .pro-img {
          width: 100px;
          height: 100px;
          margin-right: 24px;
          background: #e6eef6;
          border-radius: 4px;
          cursor: pointer;
        }
        .pro-info {
          margin-right: 18px;
          height: 100px;
        }
        .spec-name {
          color: #909399;
          font-weight: 400;
        }
      }
    }
  }

  .no-order-box {
    width: 100%;
    min-height: 600px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #222;

    .btn {
      padding: 6px 14px;
      color: #757575;
      border-radius: 32px;
      border: 2px solid #757575;
      background: #fff;
      cursor: pointer;
    }

    .btn_color {
      background: var(--btnBgColor);
      border-color: var(--btnBgColor);
      color: #fff;
    }
  }
</style>
