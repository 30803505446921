<template>
  <div class="contentindex">
    <div class="user_nav">
      <div class="user_card" v-push="`/cart`">
        <div class="user_card_title">
          <img src="~img/header_cart_01.svg" />
          <b>{{ $t('Shopping Cart') }}</b>
        </div>
      </div>

      <div class="user_card">
        <div class="user_card_title">
          <img src="~img/user_nav_01.svg" />
          <b>{{ $t('Orders') }}</b>
        </div>
        <div class="user_card_item">
          <div class="cup" @click="setShowPage({ page: 'orderList', selectType: 'All Orders' })" :class="{ xuan: userStore.userShowPage.selectType == 'All Orders' }">
            {{ $t('All Orders') }}
          </div>
          <div class="cup" @click="setShowPage({ page: 'orderList', selectType: 'NOPAID' })" :class="{ xuan: userStore.userShowPage.selectType == 'NOPAID' }">
            {{ $t('To Pay') }}
          </div>
          <div class="cup" @click="setShowPage({ page: 'orderList', selectType: 'UNDISTRIBUTED' })" :class="{ xuan: userStore.userShowPage.selectType == 'UNDISTRIBUTED' }">
            {{ $t('To Ship') }}
          </div>
          <div class="cup" @click="setShowPage({ page: 'orderList', selectType: 'DISTRIBUTED' })" :class="{ xuan: userStore.userShowPage.selectType == 'DISTRIBUTED' }">
            {{ $t('To Receive') }}
          </div>
          <div class="cup" @click="setShowPage({ page: 'orderList', selectType: 'RECEIPTED' })" :class="{ xuan: userStore.userShowPage.selectType == 'RECEIPTED' }">
            {{ $t('To Review') }}
          </div>
          <div class="cup" @click="setShowPage({ page: 'orderList', selectType: 'CLOSED' })" :class="{ xuan: userStore.userShowPage.selectType == 'CLOSED' }">
            Cancelled
          </div>
        </div>
      </div>

      <div class="user_card" v-if="getEnv() === 'development'">
        <div class="user_card_title">
          <img src="~img/user_nav_01.svg" />
          <b>Campaign</b>
        </div>

        <div class="user_card_item">
          <div class="cup" @click="setShowPage({ page: 'cashback', selectType: 'cashbackList' })" :class="{ xuan: userStore.userShowPage.selectType == 'cashbackList' }">
            Cashback
          </div>
        </div>
      </div>

      <div class="user_card">
        <div class="user_card_title">
          <img src="~img/user_nav_02.svg" />
          <b>{{ $t('Coupon') }}</b>
        </div>
        <div class="user_card_item">
          <div class="cup" @click="setShowPage({ page: 'userCoupon', selectType: 'Collect Coupons' })" :class="{ xuan: userStore.userShowPage.selectType == 'Collect Coupons' }">
            Collect Coupons
          </div>
          <div class="cup" @click="setShowPage({ page: 'userCoupon', selectType: 'Available Coupons' })" :class="{ xuan: userStore.userShowPage.selectType == 'Available Coupons' }">
            {{ $t('Available Coupons') }}
          </div>
          <div class="cup" @click="setShowPage({ page: 'userCoupon', selectType: 'Coupons History' })" :class="{ xuan: userStore.userShowPage.selectType == 'Coupons History' }">
            {{ $t('Coupons History') }}
          </div>
        </div>
      </div>

      <div class="user_card">
        <div class="user_card_title">
          <img src="~img/user_nav_03.svg" />
          <b>{{ $t('Account Settings') }}</b>
        </div>
        <div class="user_card_item">
          <div class="cup" @click="setShowPage({ page: 'userInformation' })" :class="{ xuan: userStore.userShowPage.page == 'userInformation' }">
            Personal Information
          </div>
          <div class="cup" @click="setShowPage({ page: 'password' })" :class="{ xuan: userStore.userShowPage.page == 'password' }">Password</div>
          <div class="cup" @click="setShowPage({ page: 'userAddress' })" :class="{ xuan: userStore.userShowPage.page == 'userAddress' }">{{ $t('Shipping Address') }}</div>
          <div class="cup" @click="setShowPage({ page: 'userMyAttention' })" :class="{ xuan: userStore.userShowPage.page == 'userMyAttention' }">
            {{ $t('My favorites') }}
          </div>
          <div class="cup" @click="setShowPage({ page: 'userHelp' })" :class="{ xuan: userStore.userShowPage.page == 'userHelp' }">{{ $t('Help Center') }}</div>
        </div>
      </div>
    </div>

    <div class="user_body">
      <order-list ref="orderListRef" v-if="userStore.userShowPage.page === 'orderList'"></order-list>
      <cashback-list ref="cashbackRef" v-if="userStore.userShowPage.page === 'cashback'"></cashback-list>
      <password ref="passwordRef" v-if="userStore.userShowPage.page === 'password'"></password>
      <return-order-list ref="returnOrderListRef" v-if="userStore.userShowPage.page === 'returnOrderList'"></return-order-list>
      <userCoupon ref="userCouponRef" v-if="userStore.userShowPage.page === 'userCoupon'" />
      <userInformation v-if="userStore.userShowPage.page === 'userInformation'" />
      <userAddress v-if="userStore.userShowPage.page === 'userAddress'" />
      <userMyAttention v-if="userStore.userShowPage.page === 'userMyAttention'" />
      <help-centet v-if="userStore.userShowPage.page === 'userHelp'" />
    </div>
  </div>
</template>

<script setup>
  import orderList from './orderList/index'
  import cashbackList from './compontent/cashback-list'
  import userCoupon from './userCoupon'
  import userInformation from './compontent/userInformation'
  import Password from './compontent/password'
  import userAddress from './userAddress'
  import userMyAttention from './userMyAttention'
  import helpCentet from './compontent/help-centet'
  import returnOrderList from './returnOrderList'
  import { getCurrentInstance, onMounted, onUpdated, ref, reactive, defineEmits, computed, watch, watchEffect } from 'vue'
  import JLTools from '@/util/JLTools'
  import { getEnv } from '@/util/func'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const setShowPage = (page) => {
    JLTools.toTop()
    userStore.setUserShowPage({ page: page.page, selectType: page.selectType })
  }

  // 监听 userShowPage 状态的变化
  watch(
    userStore.userShowPage,
    (newVal, oldVal) => {
      if (!userStore.userInfo.userId && newVal.page !== 'userHelp') {
        router.push({
          path: '/login'
        })
      }
    },
    { immediate: true }
  )

  onMounted(() => {
    if (!userStore.userShowPage.page) {
      setShowPage({ page: 'cashback', selectType: 'All Orders' })
    }
  })
</script>

<style scoped>
  .contentindex {
    width: var(--minWidth);
    margin: 0 auto;
  }
  .user_nav {
    float: left;
    width: 220px;
    min-height: calc(100vh - 150px);
    background: #fff;
    margin: 20px 0;
    padding: 10px 20px;
    box-shadow: 0 0 15px #e4e4e4;
  }
  .user_nav .user_card {
    float: left;
    width: 100%;
    border-bottom: 1px solid #f5f5f5;
  }
  .user_nav .user_card:last-child {
    border-bottom: 0px;
  }
  .user_nav .user_card_title {
    float: left;
    width: 100%;
    padding: 15px 0;
  }
  .user_nav .user_card_title img {
    float: left;
    width: 16px;
    height: 16px;
    background: #fff;
    margin: 3px 8px 0 0;
  }
  .user_nav .user_card_title b {
    float: left;
    width: calc(100% - 28px);
    font-size: 14px;
    height: 20px;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .user_nav .user_card_item {
    width: 100%;
    margin-bottom: 10px;
    font-size: 14px;
    cursor: pointer;
    .cup {
      width: 100%;
      line-height: 20px;
      padding-left: 25px;
      margin-bottom: 10px;
      &:hover {
        color: var(--btnBgColor);
        text-decoration: underline;
      }
    }
    .xuan {
      color: var(--btnBgColor);
      font-weight: bold;
    }
  }

  .user_body {
    float: right;
    width: calc(98% - 220px);
    min-height: calc(100vh - 150px);
    margin: 20px 0 20px 20px;
  }
</style>
