<script setup>
  import codeLogin from '@/views/login/compontent/code-login'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const data = reactive({
    loading: false,
    isInitPassWord: false,
    loginMethodType: ''
  })

  const loginMethodList = [
    { type: 'setPassword', name: '设置密码' },
    { type: 'forgotPassword', name: '修改密码' }
  ]

  // 切换登录类型
  const changeLoginMode = () => {
    data.loginMethodType = data.isInitPassWord === 'false' ? loginMethodList[0].type : loginMethodList[1].type
    // console.log(data.loginMethodType, 'data.loginMethodType')
  }

  const isPassWordChanged = () => {
    data.loading = true
    api.user
      .isPassWordChanged()
      .then((res) => {
        console.log(res, '密码是否修改过')
        data.isInitPassWord = res
        changeLoginMode()
      })
      .catch((err) => {
        console.log(err, 'err')
        proxy.$toast(`${err.title}`)
      })
      .finally(() => {
        data.loading = false
      })
  }

  onMounted(() => {
    if (!userStore.userInfo.userId) {
      return proxy.$router.push({
        path: '/login'
      })
    }
    isPassWordChanged()
  })
</script>

<template>
  <div class="page-content" v-loading="data.loading">
    <div class="pro-main-breadcrumb rowSC">
      <span>{{ $t('Home') }}</span>
      <img src="~img/down_icon.svg" />
      <b>{{ $t('Password settings') }}</b>
    </div>
    <div class="form-box"  v-if="!data.loading">
      <code-login :loginType="data.loginMethodType" :isLable="true" :notInline="true" @changeLoginMode="changeLoginMode" />
    </div>
  </div>
</template>

<style lang="less" scoped>
  .page-content {
    width: 100%;
    min-height: 600px;
  }
  .pro-main-breadcrumb {
    width: 100%;
    padding: 12px 0;
    font-size: 13px;
    line-height: 30px;

    img {
      margin: 9px;
      width: 12px;
      height: 12px;
      transform: rotate(270deg);
    }
  }

  .form-box {
    width: 480px;
  }
</style>
