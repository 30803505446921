<script setup>
  import addressViewDalog from '@/views/settlement/compontent/addressView-dialog'
  import { defineComponent, getCurrentInstance, onMounted, ref, reactive, toRefs, watchEffect, computed, nextTick, defineEmits } from 'vue'
  import api from '@/api'
  import JLTools from '@/util/JLTools'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const { proxy } = getCurrentInstance()

  const $emit = defineEmits(['sync-default-address'])

  const data = reactive({
    loading: false,
    //收货地址列表
    addressList: [],
    //Modal框的地址信息
    addressData: {
      consignee: '',
      mobile: '',
      province: '',
      city: '',
      address: '',
      defAddress: false
    },
    defaultAddress: {},
    userId: '', // 用户id
    modalTitle: '',
    curIndex: 0
  })

  const addressViewDalogRef = ref()

  const formattedAddress = (address, city, province) => {
    const fullAddress = `${address},${city},${province}`
    return fullAddress.replace(/,+/g, ',')
  }

  // 查询地址
  const getAddressList = () => {
    if (data.loading) return
    data.loading = true
    let parms = {}
    parms['userId.equals'] = data.userId
    parms.sort = 'lastModifiedDate,desc'
    api.address
      .handleAddress(parms)
      .then((res) => {
        userStore.setAddressList(res)
        data.addressList = res
        console.log(data.addressList, 'data.addressList地址列表')
        data.defaultAddress = {}
        for (let j = 0; j < data.addressList.length; j++) {
          data.addressList[j].checkbox = false
          if (data.addressList[j].defAddress == true) {
            data.addressList[j].checkbox = true
            data.defaultAddress = data.addressList[j]
            break
          }
        }
      })
      .finally(() => {
        data.loading = false
      })
    proxy.$forceUpdate()
  }

  /**
   * 设置为当前收货地址model
   * @param address
   * @param index
   */
  const setdefAddress = (address, index) => {
    data.addressList[index].defAddress = true
    api.address.handleAddress(data.addressList[index], 'put').then((res) => {
      console.log(res, '修改地址')
      getAddressList()
    })
  }

  /**
   * 编辑地址
   * @param address
   */
  const editTheAddress = (address) => {
    data.modalTitle = proxy.$t('Edit shipping address')
    data.addressData = address
    showModal()
  }

  /**
   * 新增地址
   */
  const addNewAddress = () => {
    data.modalTitle = proxy.$t('New shipping address')
    data.addressData = {}
    data.addressData.defAddress = false
    showModal()
  }

  // 删除地址
  const del = (id) => {
    proxy.$Message.confirm({
      title: proxy.$t('Revome Address'),
      msg: proxy.$t('Are you sure you want to remove the address?'),
      okText: 'Remove',
      onOk: () => {
        api.address.handleAddress(id, 'delete').then((res) => {
          getAddressList()
          proxy.$toast(proxy.$t('Address removed successfully'), { type: 'succeed' })
        })
      },
      onCancel: () => {
        getAddressList()
      }
    })
  }

  // 地址弹窗展示
  const showModal = async () => {
    await nextTick()
    addressViewDalogRef.value.showModal()
  }

  // 更改地址信息
  const changeAddress = (address) => {
    console.log(address)
    data.addressData.province = address.province
    data.addressData.city = address.city
    data.addressData.areaNo = address.areaNo
    console.log('changeAddress:', data.addressData)
  }

  /**
   * 保存地址
   */
  const saveTheAddress = (newAddress) => {
    console.log('newAddress::', newAddress)
    newAddress.userId = data.userId
    newAddress.country = ''
    newAddress.zipCode = '100111'
    let request
    console.log(newAddress, 'newAddress')
    if (JLTools.isNull(newAddress.id)) {
      request = api.address.handleAddress(newAddress, 'post')
    } else {
      request = api.address.handleAddress(newAddress, 'put')
    }
    request
      .then((res) => {
        addressViewDalogRef.value.hideModal()
        getAddressList()
      })
      .catch((error) => {
        proxy.$toast('Please fill all the information!')
      })
  }

  onMounted(() => {
    data.userId = userStore.userInfo.userId
    getAddressList()
  })
</script>

<template>
  <div>
    <div class="pro_main_breadcrumb rowSC">
      <span>{{ $t('Home') }}</span>
      <img src="~img/down_icon.svg" />
      <b>{{ $t('Shipping Address') }}</b>
    </div>
    <div class="user_account">
      <button class="add_address rowSC" @click="addNewAddress">+ {{ $t('New Shipping Address') }}</button>
      <div class="settlement_address_item rowBC" v-for="(item, index) of data.addressList" :key="index">
        <div class="rowSC">
          <div class="icon">
            <img class="no1" src="~img/address_icon_01.svg" />
          </div>
          <div class="info">
            <div class="name_phone rowSC">
              <div class="biao rowCC" v-if="item.defAddress">{{ $t('Default') }}</div>
              <div class="name">{{ item.consignee }}</div>
              <div class="phone">{{ '+92' }}&nbsp;{{ item.mobile }}</div>
            </div>
            <div class="address">
              {{ formattedAddress(item.address, item.city, item.province) }}
            </div>
          </div>
        </div>
        <div class="btn-box">
          <div class="rowSC row-reverse">
            <div class="btn btn-primary" @click="del(item.id)">{{ $t('Delete') }}</div>
            <div class="btn btn-primary" @click="editTheAddress(item)">{{ $t('Edit') }}</div>
            <div class="btn btn-primary" @click="setdefAddress(item, index)" v-if="!item.defAddress">{{ $t('Default') }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 新增编辑地址弹窗 -->
    <addressViewDalog ref="addressViewDalogRef" :addressData="data.addressData" :modalTitle="data.modalTitle" @saveTheAddress="saveTheAddress"></addressViewDalog>
  </div>
</template>

<style lang="less" scoped>
  .pro_main_breadcrumb {
    width: 100%;
    padding: 12px 0;
    font-size: 13px;
    span {
      line-height: 30px;
    }
    img {
      margin: 9px;
      width: 12px;
      height: 12px;
      transform: rotate(270deg);
    }
    b {
      line-height: 30px;
    }
  }

  .user_account {
    width: 100%;
    min-height: 590px;
    background: #fff;
    .add_address {
      padding: 10px 18px;
      margin-bottom: 10px;
      height: 30px;
      background: var(--btnBgColor);
      color: #fff;
      border-radius: 24px;
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
    .settlement_address_item {
      width: 100%;
      border: 1px dashed #ccc;
      position: relative;
      padding: 15px;
      margin-top: 10px;
      &:first-child {
        margin-top: 0px;
      }
      &:hover {
        border: 1px dashed var(--btnBgColor);
        background: #e9e5e5;
        .btn-box {
          display: block;
          .row-reverse {
            flex-direction: row-reverse;

          .btn-primary,
          .btn-secondary {
            border-radius: 50px;
          }
          }
        }
        .icon .no1 {
          display: block;
        }
      }
      .icon {
        width: 20px;
        height: 20px;
        margin: 10px 0;
      }
      .info {
        width: calc(100% - 300px);
        padding-left: 10px;
        .name_phone {
          width: 100%;
          line-height: 20px;
        }
        .biao {
          height: 16px;
          padding: 2px 8px;
          margin: 3px 5px 1px 0;
          font-size: 10px;
          background: #222;
          border-radius: 10px;
          color: #fff;
        }
        .name {
          font-size: 16px;
        }
        .phone {
          font-size: 14px;
          margin-left: 10px;
        }
        .address {
          width: 600px;
          line-height: 20px;
          margin-top: 5px;
          color: #999;
          word-wrap: break-word;
        }
      }
      .btn-box {
        width: 200px;
        display: none;
        .btn {
          width: 55px;
          margin-right: 10px;
          text-align: center;
          height: 26px;
          line-height: 24px;
          background: #fff;
          border: 1px solid var(--btnBgColor);
          color: var(--btnBgColor);
          &:hover {
            cursor: pointer;
            background: var(--btnBgColor);
            color: #fff;
          }
        }
      }
    }
  }

  .settlement_address_item.xuan {
    border: 1px dashed var(--btnBgColor);
    background: #e9e5e5;
    .icon .no1 {
      display: block;
    }
  }
</style>
