<script setup>
  import { userInfoIcons } from '@/enum/haier'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits } from 'vue'
  import JLTools from '@/util/JLTools'

  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const list = [
    { ...userInfoIcons.Whatsapp, ...{ hint: 'Whatsapp us for general queries, from 9 AM to 5 PM Monday to Saturday', sup: '+923144442427' } },
    { ...userInfoIcons.Email, ...{ hint: 'Email us for general queries, including marketing and partnership opportunities.', sup: 'info@haier.com.pk' } },
    {
      ...userInfoIcons.Support,
      ...{ hint: 'Check out helpful resources, FAQs and developer tools.', sup: '042-111142437', link: 'https://www.haier.com/pk/service-support/', linkName: 'Service & Support' }
    }
  ]

  const handleHelp = (item) => {
    switch (item.type) {
      case 'Whatsapp':
        goChat()
        break
      default:
        break
    }
  }

  // 跳转客服
  const goChat = () => {
    let url = ''
    const encoded = encodeURI(url)
    const whatsapp = `https://wa.me/+923144442427`
    console.log(whatsapp, 'whatsapp')
    window.open(whatsapp, '_blank')
  }
</script>

<template>
  <div class="rowCC content-box">
    <div class="content rowBC">
      <div v-for="(item, index) in list" :key="index" class="item columnCC" @click="handleHelp(item)">
        <div class="icon-box">
          <JLImg class="ico" :src="item.url"></JLImg>
        </div>
        <div class="title">{{ item.en_name }}</div>
        <div class="text mt-1">{{ item.hint }}</div>
        <div class="emphasis-box">
          <a v-if="item.link" :href="item.link" target="_blank">{{ item.linkName }}</a>
          <div class="mt-1 emphasis">{{ item.sup }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .content-box {
    width: 100%;
    height: 100%;
    height: 680px;
  }
  .content {
    width: 100%;
    height: 400px;
    padding: 0 20px;
    margin: 0 50px;
    border-radius: 9px;
    border: 1px solid #e1e1e1;
    background: #fffefe;
    box-sizing: border-box;
    .item {
      flex-grow: 1;
      flex-basis: 0;
      height: 100px;
      margin: 0 20px;
      font-size: 14px;
      color: #222;
      &:first-child {
        cursor: pointer;
      }
      .icon-box {
        padding: 10px;
        border-radius: 6px;
        background: rgba(237, 37, 47, 0.1);
        .ico {
          width: 44px;
          height: 44px;
          vertical-align: middle;
        }
      }
      .title {
        margin-top: 24px;
        font-size: 20px;
        font-weight: 700;
      }
      .text {
        min-height: 80px;
        line-height: 24px;
        text-align: center;
      }
      .emphasis-box {
        width: 100%;
        min-height: 60px;
        text-align: center;
        a {
          color: var(--btnBgColor);
        }
      }
      .emphasis {
        font-weight: 700;
      }
    }
  }
</style>
