<template>
  <div class="hender-box">
    <div class="title-box">
      <p class="title">My Orders</p>
      <p class="sub-title">You can view all orders you have placed here</p>
    </div>
    <div class="rowBC opt-box">
      <el-input class="custom-input" v-model="data.search" style="width: 240px" placeholder="Search order number...." @keyup.enter="search" :prefix-icon="Search" clearable />
      <el-select v-model="data.type" placeholder="Select" style="width: 240px" @change="changeType">
        <el-option v-for="(item, index) in tabs" :key="index" :label="item.typeName" :value="item.type" />
      </el-select>
    </div>
  </div>

  <div class="content" v-loading="data.loading">
    <div v-if="!data.loading">
      <order-table :list="data.orderList" :noListTitle="data.noListTitle" @updataStatus="updataStatus" />
      <div class="fenye-box rowBC" v-if="paginationData.total">
        <div class="fenye-left">
          {{ $t('About') }} <b>{{ paginationData.total }}</b> {{ $t('results were found') }}
        </div>
        <div class="fenye-right">
          <Pagination :config="{ total: paginationData.total, pageSize: paginationData.pageSize, currentPage: paginationData.page }" @currentPage="pageList"></Pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { Search } from '@element-plus/icons-vue'
  import orderTable from './order-table'
  import Pagination from '@/components/Pagination'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits, defineExpose, watch, provide } from 'vue'
  import { mergeProperties } from '@/util/func'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const tabs = [
    { type: 'All Orders', typeName: 'All Orders', name_zh: '全部订单' },
    { type: 'NOPAID', typeName: 'To Pay', name_zh: '待付款' },
    { type: 'UNDISTRIBUTED', typeName: 'To Ship', name_zh: '待发货' },
    { type: 'DISTRIBUTED', typeName: 'To Receive', name_zh: '已发货' },
    { type: 'RECEIPTED', typeName: 'To Review', name_zh: '已收货' },
    { type: 'CLOSED', typeName: 'Cancelled', name_zh: '已取消' }
  ]

  const data = reactive({
    type: '',
    search: '', // 搜索
    loading: true,
    orderList: [],
    noListTitle: ''
  })

  const paginationData = reactive({
    page: 1,
    pageSize: 4,
    total: 0
  })

  watch(
    () => userStore.userShowPage.selectType,
    (newVal, oldVal) => {
      if (newVal !== oldVal) {
        data.type = userStore.userShowPage.selectType
        changeType()
      }
    }
  )

  const search = () => {
    paginationData.page = 1
    paginationData.total = 0
    data.orderList = []
    queryOrderList('search')
  }

  //查询订单列表
  const queryOrderList = (type) => {
    type = type || 'init'
    data.loading = true
    let params = {
      'userId.equals': userStore.userInfo.userId,
      sort: 'createdDate,desc',
      page: paginationData.page - 1,
      'search.contains': data.search,
      size: paginationData.pageSize,
      'orderStatus.equals': data.type === 'All Orders' ? '' : data.type
    }
    api.order
      .orderList(params)
      .then((res) => {
        if (res && res.content && res.content.length) {
          console.log(res, '订单列表')
          res.content.forEach((it) => {
            let subOrderSpecs = []
            subOrderSpecs = it.subOrders.map((item) => {
              item.subOrderSpecs.map((specs) => {
                specs.productName = item.productName
              })
              return item.subOrderSpecs
            })
            it.subOrderSpecs = [].concat(...subOrderSpecs)
          })
          data.orderList = res.content
          paginationData.total = res.totalElements
        } else {
          console.log('dddd')
          data.noListTitle = type === 'search' ? 'Order not found' : 'There are no orders placed yet.'
        }
        JLTools.toTop()
        data.loading = false
      })
      .catch(() => {
        data.loading = false
      })
  }

  // 分页处理
  const pageList = (val) => {
    console.log(val)
    paginationData.page = val
    queryOrderList()
  }

  // 切换tab
  const changeType = (type) => {
    type = type || data.type
    paginationData.page = 1
    paginationData.total = 0
    data.orderList = []
    data.type = type
    userStore.setUserShowPage({ selectType: type })
    queryOrderList()
  }

  const updataStatus = (obj) => {
    console.log('更新状态', obj)
    if (data.orderList[obj.index].orderStatus) {
      data.orderList[obj.index].orderStatus = obj.status
    }
  }

  onMounted(() => {
    data.type = userStore.userShowPage.selectType || tabs[0].type
    changeType()
  })

  provide('updataStatus', updataStatus)
</script>

<style lang="less" scoped>
  .hender-box {
    width: 100%;
    .title {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
    }
    .sub-title {
      font-size: 14px;
      line-height: 20px;
      color: #909399;
    }
    .opt-box {
      margin-top: 24px;
    }
    :deep(.el-select .el-input) {
      height: 38px;
    }
    :deep(.el-input__wrapper) {
      border-radius: 4px !important;
    }
    :deep(.el-input__wrapper) {
      height: 38px;
    }
    :deep(.el-input__prefix .el-icon) {
      color: #a8abb2;
    }
  }

  .content {
    position: relative;
    width: 100%;
    min-width: 400px;
    height: auto;
    min-height: 600px;
    margin-top: 24px;
  }
  .fenye-box {
    padding: 12px 0;
    .fenye-left {
      margin-left: 10px;
      font-size: 13px;
      color: #666;
      b {
        font-size: 14px;
        color: #000;
      }
    }
    .fenye-right {
      margin-right: 5px;
    }
  }
</style>
