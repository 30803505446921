<script setup>
  import couponList from '@/views/userinfo/coupon/CouponIndex'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits, watch } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const tabs = [
    { type: 'collect', name: 'Collect Coupons', zhName: '可领取优惠卷列表', isLoaded: false, list: [] },
    { type: 'Available', name: 'Available Coupons', zhName: '可使用优惠卷列表', isLoaded: false, list: [] },
    { type: 'History', name: 'Coupons History', zhName: '优惠卷历史列表', isLoaded: false, list: [] }
  ]

  const data = reactive({
    type: '',
    menuIndex: 0
  })

  const changeType = () => {
    data.menuIndex = tabs.findIndex((tab) => tab.name === data.type)
    console.log(data.menuIndex, 'data.menuIndex')
  }

  watch(
    () => userStore.userShowPage.selectType,
    (newVal, oldVal) => {
      console.log(newVal, 'newVal')
      console.log(oldVal, 'oldVal')
      if (newVal !== oldVal) {
        data.type = userStore.userShowPage.selectType
        userStore.setUserShowPage({ selectType: data.type })
        changeType()
      }
    },
    { immediate: true }
  )
</script>

<template>
  <coupon-list :menuIndex="data.menuIndex" :isBox="true"></coupon-list>
</template>

<style lang="less" scoped></style>
