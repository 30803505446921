<script setup>
  import zindagiPopup from '@/views/activity/zindagi/compontent/zindagi-popup.vue'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits, nextTick } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const props = defineProps({
    item: {
      type: Object,
      default: () => {}
    }
  })
</script>

<template>
  <el-descriptions class="margin-top" title="Personal Details" :column="3" border direction="vertical">
    <el-descriptions-item label="Name:">{{ item.name }}</el-descriptions-item>
    <el-descriptions-item label="Phone No:">{{ item.phone_no }}</el-descriptions-item>
    <el-descriptions-item label="ID Card No:">{{ item.id_card_no }}</el-descriptions-item>
    <el-descriptions-item label="ID Card lssuance Date:">{{ item.id_card_issuance_date }}</el-descriptions-item>
  </el-descriptions>

  <el-descriptions class="mt20" title="Purchase Details" :column="3" border direction="vertical">
    <el-descriptions-item label="AC Quantaty:">{{ item.ac_quantity }}</el-descriptions-item>
    <el-descriptions-item label="Purchase Date:">{{ item.purchase_date }}</el-descriptions-item>
    <el-descriptions-item label="Installation Request No:">{{ JSON.parse(item.installation_request_number)[0] }}</el-descriptions-item>
    <el-descriptions-item label="Installation Completion Date:">{{ item.installation_complete_date }}</el-descriptions-item>
    <el-descriptions-item label="Invoice Picture" class="colunmSC">
      <div class="img-box">
        <img-upload :imgs="JSON.parse(item.invoice_picture)" :isDelete="false" />
      </div>
    </el-descriptions-item>
    <el-descriptions-item label="Warranty Card" class="colunmSC">
      <img-upload class="img-box" :imgs="JSON.parse(item.warranty_card_picture)" :isDelete="false" />
    </el-descriptions-item>
  </el-descriptions>
</template>

<style lang="less" scoped>
  .img-box {
    width: 33%;
  }
</style>
